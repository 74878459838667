import React from "react";
import "./Voting.css";
import voting from "../../asset/image/voting.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

// 
const Voting = () => {
  const [showVotingText, setshowVotingText] = React.useState(false)
    const ToggleTextVoting = () =>{
        setshowVotingText(!showVotingText)
    }
  return (
    <div className="chain-voting" id="voting">
      <Container>
        <Fade bottom>
          <div className="logo-part">
            <img className="img-fluid" src={voting} alt="" />
            <p className="giveWayHeading">オンチェーン投票</p>
          </div>
        </Fade>
        <Fade bottom>
          <div className="voting-des-box p-3 p-md-5 m-md-5 text-start">
            <p className="voting-des">
            我々は、NFT保有者の意見がコミュニティの成長の礎になると考えています
そのため、我々は所有者がコミュニティ開発の方向性に投票できる投票システムを開発します。
以下詳細です。また今後、下記以外の他のシステムも追加する可能性があります。
            </p>
            <ul className="voting-des">
              <li className="vot-list">●スポンサーアーティストのリストについて</li>
              <li className="vot-list">●オフ会/インタビューの招待リストについて</li>
              <li className="vot-list">●オンライン抽選ギフトアイテム一覧について</li>
              <li className="vot-list">
              ●特定のグループに付与する権利について
              </li>
              <li className="vot-list">
              ●仮想世界展に展示できる芸術品について
              </li>
            </ul>
              <p className={showVotingText ? "voting-des show-voting-text-active" : "voting-des show-voting-text"}>
              今後もコミュニティの意見を重要な参考指標として活用し、コミュニティ
の発展に参加できる権利を保有者に提供していきます。
各メンバーが所有するNFTの数によって、投票の重みが決まります。
 NFTが多いほど、影響力は大きくなります
              </p>
              <div className="voting-learn-more">
                <button type="button" className="learn-more" onClick={ToggleTextVoting}>Learn More <i class="fa fa-chevron-down" aria-hidden="true"></i></button>
              </div>
            {/* <ul className='voting-des'>
                    <li className='listing'>Proposers are the only group in the system that can set up on-chain voting.</li>
                    <li className='listing'>The Executors are responsible for executing the voting process and the final results:</li>
                    <li className='listing'>we can assign this role to a person at a particular address to allow it to perform on
                    <li className='listing'>behalf of the original executor. </li>
                        Finally, the Admin role has the power to appoint different roles: this is a crucial and
                        sensitive role</li>
                </ul> */}
            {/* <p className='voting-des'>
                We will then create an application to allow users to vote on-chain and check the result.
                </p> */}
          </div>
        </Fade>
      </Container>
      {/* <a href="#bonus"><button className='scrollTop mt-3'  style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default Voting;
