import { useState } from "react";
import "./Bonuse.css";
import logo from "../../asset/image/bonus-logo.png";
import img1 from "../../asset/image/b-img-1.png";
import img2 from "../../asset/image/b-img-2.png";
import img3 from "../../asset/image/b-img-3.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

const Bonuse = () => {
  const [showBonus, SetshowBonus] = useState(false);
  const ToggleBonusText = () => {
    SetshowBonus(!showBonus);
  };
  return (
    <div className="bonuse-section pb-5">
      <Container className="section-wraper pb-5">
        <Fade bottom>
          <div className="logo-part">
            <img src={logo} alt="" />
            <p className="giveWayHeading pt-4">SPECIAL BONUSES</p>
          </div>
        </Fade>

        <div className="bonuse-box mx-md-5" id="bonus">
          <Fade bottom>
            <div className="bonuse-content">
              <div className="pe-md-4 pt-5 left">
                <p className="bonus-title">
                  フリーエデン
                  NFTのステークホルダーには、特別なボーナスが与えられます
                </p>
                {/* <p className={showBonus ? 'bonus-description show-bonus-text-active' : 'bonus-description show-bonus-text'}>The 2D and 3D lottery activities have different prizes, and the 3D lottery */}
                <p className={"bonus-description"}>
                  -
                  2D-NFTホルダープール／3D-NFTホルダーくじ：2D-NFTと3D-NFTのホルダーにはそれぞれ異なるプールがあります。
                  - 3D NFTプールの賞品は、2Dよりも良いものになります。
                </p>
                {!showBonus ? (
                  <div className="bonus-learn-more">
                    <button
                      type="button"
                      className="learn-more"
                      onClick={ToggleBonusText}
                    >
                      Learn More{" "}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="ps-md-4 right">
                <img className="bonuse-img" src={img1} alt="" />
              </div>
            </div>

            {/* desktop full content */}
            <div className="d-md-block d-none">
              <div className="bonuse-content">
                <div className="pe-md-4 left">
                  <img className="bonuse-img" src={img2} alt="" />
                  {/* <p>Mouse Pads / T-Shirts / Hoodies / Coaster / Tote Bag / Pillow</p> */}
                </div>
                <div className="ps-md-4 right">
                  <p className="bonus-title">NFTホルダー2名</p>
                  <p className="bonus-description">
                    NFTを2つ購入すると、ユニークなアバターアクセサリー（光りもの）がもらえます。
                    <br /> <br />
                    お土産には、以下の6つのオプションから2つを選択できます。マウスパッド/
                    Tシャツ/パーカー/コースター/トートバッグ/枕送料は手配いたしますが、受取人が送料を支払う必要があります。
                    {/* <p>Souvenirs: Holders with two NFTs can choose two out of five tangible products (as above);
                        FreEden will take care of the delivery, and the buyers have to cover the shipping charges.</p> */}
                  </p>
                </div>
              </div>
              <div className="bonuse-content">
                <div className="pe-md-4 pt-5 left">
                  <p className="bonus-title">NFTを３つお持ちの方</p>
                  <p className="bonus-description">
                  
1.記念品: NFTを2つお持ちの方は、上記6種類の記念品の中から5種類をお選びいただけます（配送はフリーエデンが行い、送料は購入者のご負担となります）。
<br/>
<br/>
週替わり抽選会: 毎週フリーエデンチームは、抽選会を開催します。複数のフリーエデン NFTをお持ちの方は、より高い当選確率となります。
<br/>
<br/>
賞品<br/>
デジタル通貨：USDT / ETH/その他の暗号通貨。<br/>
オンラインサービス用ポイントカード：Amazonギフトカード/NetFlixカードなど。

                  </p>
                </div>
                <div className="ps-md-4 right">
                  <img className="bonuse-img" src={img3} alt="" />
                </div>
              </div>
            </div>
            {/* mobile content */}
            <div className="d-md-none d-block">
              {showBonus ? (
                <>
                  <div className="bonuse-content">
                    <div className="pe-md-4 left">
                      <img className="bonuse-img" src={img2} alt="" />
                      {/* <p>Mouse Pads / T-Shirts / Hoodies / Coaster / Tote Bag / Pillow</p> */}
                    </div>
                    <div className="ps-md-4 right">
                      <p className="bonus-title">NFTホルダー2名</p>
                      <p className="bonus-description">
                        NFTを2つ購入すると、ユニークなアバターアクセサリー（光りもの）がもらえます。
                        <br /> <br />
                        お土産には、以下の6つのオプションから2つを選択できます。マウスパッド/
                        Tシャツ/パーカー/コースター/トートバッグ/枕送料は手配いたしますが、受取人が送料を支払う必要があります。
                        {/* <p>Souvenirs: Holders with two NFTs can choose two out of five tangible products (as above);
                        FreEden will take care of the delivery, and the buyers have to cover the shipping charges.</p> */}
                      </p>
                    </div>
                  </div>
                  <div className="bonuse-content">
                    <div className="pe-md-4 pt-5 left">
                      <p className="bonus-title">NFTを３つお持ちの方</p>
                      <p className="bonus-description">
                      

1.記念品: NFTを2つお持ちの方は、上記6種類の記念品の中から5種類をお選びいただけます（配送はフリーエデンが行い、送料は購入者のご負担となります）。
<br/>
<br/>
週替わり抽選会: 毎週フリーエデンチームは、抽選会を開催します。複数のフリーエデン NFTをお持ちの方は、より高い当選確率となります。
<br/>
<br/>
賞品<br/>
デジタル通貨：USDT / ETH/その他の暗号通貨。<br/>
オンラインサービス用ポイントカード：Amazonギフトカード/NetFlixカードなど。

                      </p>
                    </div>
                    <div className="ps-md-4 right">
                      <img className="bonuse-img" src={img3} alt="" />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </Fade>
        </div>
      </Container>
      {/* <a href="#ourTeam"><button className='scrollTop mt-3'  style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default Bonuse;
