import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import evennt1 from "../../asset/metaEvents/event1.png";
import evennt2 from "../../asset/metaEvents/event2.png";
import evennt3 from "../../asset/metaEvents/event3.png";
import evennt4 from "../../asset/metaEvents/event4.png";
import evennt5 from "../../asset/metaEvents/event5.png";
import evennt6 from "../../asset/metaEvents/event6.png";
import topLine from "../../asset/metaEvents/topLine.png";
import bottomLine from "../../asset/metaEvents/bottomLine.png";
import "./MetaEvents.css";

const MetaEvents = () => {

  let data = [
    {
      img: evennt1,
      title: `1.メタバースアニメコンサート`,
      body: `我々は、ユニークなACG作品を紹介、
      展示するために、沢山のアーティストをご招待します。ミートアップや
      インタビューを開催し、我々のメンバーを唯一無二の世界にお連れします。
      `,
    },
    {
      img: evennt2,
      title: `2. アニメ音楽フェスは、 `,
      body: `我々のメタバースコンサートを開催するために
      ACG歌手や声優をご招待します。我々は、メンバーが更なる才能を見つけ、
      新しいアーティストたちとこの業界を盛り上げるために、より多くの
      視点を皆様に提供できるよう絶え間ない努力を続けます。`,
    },
    {
      img: evennt3,
      title: `3. Vtuberミートアップは `,
      body: `Vtuberバーチャルワールドミーティングで
      開催されます。ファンは、メタバースにて自分のお気に入りのアイドルと
      交流をすることができます。そして、exclusiveプライベートコンサートや
      小型のコンサートに参加することができます。
      `,
    },
    {
      img: evennt4,
      title: `4.Exclusiveオンラインオークション`,
      body: `FREEDENのメンバーに向けて、
      exclusiveアニメ作品を作るために、世界中からACGアーティストと
      コラボしました。作品は、NFTの形式で販売され、メンバーが安全に
      NFT作品を購入できるよう、Discordもしくはメタバースにて開催され
      ます。オークションに参加したい方々は、身分証の確認とブロック
      チェーンウォーレットと接続をして、取引の安全を確保してください。
      これによって、荒らしや悪意ある嫌がらせから守ることができます。      
    `,
    },
  ];
  return (
    <div className="metaEvents" id="metaevent">
      <div>
        <Fade bottom>
          <img className="mt-5" src={eventLogo} alt="" />
          <p className="metaHeading">Metaverse ACG Events</p>
          <p className="meta-description px-3 mb-5">
          メタバースアニメコンサート / アニメ音楽フェス / Vtuber ミートアップ
/Exclusive オンラインオークション<br/>我々は、将来コミュニティの投票を
通じて、更なるメタバースイベントを開催します。
          </p>
        </Fade>
      </div>
      <Container>
        <img className="img-fluid mb-5" src={topLine} alt="" />
        <div className="desktop-metaevents">
          <div className="text-start">
            <Row xs={1} md={2} className="g-5 mx-0 px-0">
              {data?.map((v, i) => (
                <Col key={i}>
                  <Fade bottom>
                    <Card className="eventCard">
                      <Card.Body>
                        <Card.Title className="event-title">
                          {v?.title}
                        </Card.Title>
                        <Card.Text className="event-text">{v?.body}</Card.Text>
                      </Card.Body>
                      <Card.Img variant="top" src={v?.img} />
                    </Card>
                  </Fade>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="mobile-metaevents">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[FreeMode, Autoplay, Pagination]}
            className="mySwiper"
          >
            {data?.map((v, i) => (
              <SwiperSlide>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        {v?.title}
                      </Card.Title>
                      <Card.Text className="event-text">{v?.body}</Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={v?.img} />
                  </Card>
                </Fade>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <img className="img-fluid mt-5 mb-5 pb-5" src={bottomLine} alt="" />
        {/* <a href="#exibition"><button className='scrollTop mb-5'>↡</button></a> */}
        <div className="px-2">
          <Fade bottom>
            <img src={eventLogo} alt="" />
          </Fade>
          <Fade bottom>
            <p className="metaHeading" id="exibition">
            オンライン個人収集作品の展覧会
            </p>
          </Fade>
          <Fade bottom>
            <p className="meta-description ms-md-5 me-md-5 ps-md-5 pe-md-5">
我々は、ファンの方々も自分のコレクションと自分の思いを展示する
機会を持つことを願っております。同時にアーティストへの指示と他の
ファンの方々へ共有をしていただきたいと考えております。
我々のメタバース空間を借りて、会員はコレクションの展示会の開催を
することができます。また、多くのNFTを保有する人は自分だけの
展示空間を設計することもできます。また友達を招待して、自分が開催
する展示会を開くこともできます。</p>
          </Fade>
        </div>
        <div></div>

        <div className="mt-5 pb-5">
          <Fade bottom>
            <div className="exibition-imgs-bg">
              <Row xs={1} md={2} className="g-5 mx-0 px-0">
                <Col>
                  <Card className="eventCard">
                    <Card.Img variant="top" src={evennt5} />
                  </Card>
                </Col>
                <Col>
                  <Card className="eventCard">
                    <Card.Img variant="top" src={evennt6} />
                  </Card>
                </Col>
              </Row>
            </div>
          </Fade>
        </div>
      </Container>
      {/* <a href="#giveaway"><button className='scrollTop mb-5'>↡</button></a> */}
    </div>
  );
};

export default MetaEvents;
