import React from "react";
import Fade from "react-reveal/Fade";
import aboutUsArt from "../../asset/image/aboutUsArt.png";
import meduim from "../../asset/image/meduimLogo.png";
// import artist from '../../asset/image/artist.png'
import "./AboutUs.css";
const AboutUs = () => {
  return (
    <div id="AboutUs" className="aboutUs">
      <img src={aboutUsArt} className="aboutus-img" alt="" />

      <Fade bottom>
        <p className="aboutus-text">
        FREEDENは、世界中のACGアーティストやファンにクリエイティブ
で没入型の体験を提供することを目的としたエキサイティングなNFT
プロジェクトです。アーティストが生き残ることがますます困難な世
界で、多くの芸術家が必死に生き延び夢を追いかけています。特に
ACGアーティストは、芸術家のグループ内で軽蔑や忘れ去られがち
なグループだと考えております。そのため、我々はファンが仮想世界
において、自分達のお気に入りのACGデジタルアート作品を共有や集
めることができるコミュニティを提供したいと考えております。同時
に、アーティストはCreate to Earnメカニズムを通じて生計を立てる
ことができます。世界中の有名な10人のACGアーティストによって
設計された6,000のジェネレーティブアートNFTで.我々のビジョン
を実現することができます。また我々のNFT所有者のために計画さ
れたエキサイティングでユニークな機会を提供する予定です...一緒
に私たちはFREEDENを見つけていきましょう。
        </p>
      </Fade>
      <Fade bottom>
        <div className="social-section">
          {/* <a href="http://www.facebook.com/freeden.io" target="blank">
            <i class="fa-brands fa-facebook-f"></i>
          </a> */}
          <a href="https://twitter.com/freedenio" target="blank">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/freeden.io/" target="blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://discord.gg/rnYW6fu3zv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa-brands fa-discord"></i>
          </a>
          <a
            href="https://medium.com/@freeden"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              <img src={meduim} alt="" />
            </i>
          </a>
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
