import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Fade } from "react-reveal";
import utlogo from "../../asset/artist/utilitiesLogo.png";
// css
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import "./Utilities.css";


const Utilities = () => {

  // utility text
  let utilityText = [
    {
      title: `✦Stageverse Exclusive Metaverse Club: `,
      body: `我々のNFTの保持者は、無料でクラブに入ることができます。クラブでは
      、本物の受付人を雇い、毎週イベントを開催します。メンバーはクラブで
      自由に交流をして、楽しむことができます。`,
    },
    {
      title: `✦Metaverseでの展示会とイベント: `,
      body: `私たちはメタバース内で、大規模の
      さまざまなイベントを開催します。例えば、展示会やVtuber meetups、
      アニメコンサートなど、可能性は無限大にあります。`,
    },
    {
      title: `✦毎週行われる抽選と景品: `,
      body: `所有者は、我々の３DNFTを無料でミントする
      ことができ、毎週行われる抽選イベントに参加して、様々な景品を獲得
      することができます。コミュニティへの提案や投票を通じて、あなたの
      欲しい景品をプールしておくことができます。`,
    },
    {
      title: `✦オフラインで授かる力とイベント: `,
      body: `熱狂的なACGファンのために、
      提供する実用的な力は、グローバルアニメコンサートサポート、
      列並び代行サービス、オフラインアニメ展示パーティ`,
    },
    {
      title: `✦オンチェーン投票: `,
      body: `我々は、熱烈にコミュニティを率いる組織です。
      我々の特別に開発されたプラットフォームを通じて、NFT保持者は
      コミュニティの将来の方向性を決めるための提案に投票をする
      ことができます。`,
    },
    {
      title: `✦スペシャルボーナス: `,
      body: ` FREEDENのNFTをステーキングしたNFTを
      一つ以上保有する人は、スペシャルボーナスを獲得数rことができます。
      `,
    },
  ];
  return (
    <div id="utilities" className="utilities">
      <div>
        <Fade bottom>
          <img src={utlogo} alt="" />
        </Fade>
        <p className="utilities-heading">U T I L I T Y</p>
      </div>
      <div className="utilities-points desktop-utility pb-md-5">
        <ul>
          {utilityText?.map((v, i) => (
            <Fade bottom key={i}>
              <li>
                <p>
                  <span className="point-heading">{v.title}</span>
                  {v.body}
                </p>
              </li>
            </Fade>
          ))}
        </ul>
      </div>
      <div className="utilities-points pb-md-5">
        <div className="mobile-utility-swiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
              {utilityText?.map((v, i) => (
            <SwiperSlide key={i}>
              <ul className="m-0 p-0">
                <li>
                  <span className="point-heading">
                   {v?.title}
                  </span>
                  <p>
                    <br />
                   {v?.body}
                  </p>
                </li>
              </ul>
            </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Utilities;
