import { useState, useRef, useEffect } from "react";
// import artistCard from "../../asset/artist/artistCard.png";
import artistCard2 from "../../asset/artist/artistCard2.jpg";
import artistJapan from "../../asset/artist/artistJapan.jpg";
import airroolChina from "../../asset/artist/airroolChina.jpg";
import demaTaiwan from "../../asset/artist/demaTaiwan.jpg";
import eichiKP from "../../asset/artist/eichiKP.jpg";
import evaUkraine from "../../asset/artist/evaUkraine.jpg";
import zeronextTaiwan from "../../asset/artist/zeronextTaiwan.jpg";
import nagiImg from "../../asset/artist/nagi.jpg";
import 倉科 from "../../asset/artist/倉科.jpg";
import 四葉 from "../../asset/artist/四葉.jpg";
// import slideImgRound from "../../asset/artist/roundSlider.png";
import ArrowDown from "../../../Component/asset/image/arrow-down.png";
import ArtistContentImgT from "../../../Component/asset/image/artist-drop-img-top.png";
import ArtistContentImgB from "../../../Component/asset/image/artist-img-drop-btm.png";
import pixivIcon from "../../../Component/asset/image/icons/pixiv.svg";
//
import arrowRight from "../../asset/artist/rightbtn.png";
import arrowLeft from "../../asset/artist/leftbtn.png";
//
import eichi_human from "../../asset/gallery/eichi_human.png";
// import art_2 from "../../asset/gallery/art_2.png";
// import art_3 from "../../asset/gallery/art_3.png";
// import art_4 from "../../asset/gallery/art_4.png";
import angel_neko from "../../asset/gallery/angel_neko.png";
import kitten_dema from "../../asset/gallery/kitten_dema.png";
import demon_airool from "../../asset/gallery/demon_airool.png";
import fairy_eva from "../../asset/gallery/fairy_eva.png";
// import art_9 from "../../asset/gallery/art_9.png";
// import art_10 from "../../asset/gallery/art_10.png";
// 
import none_img from "../../asset/gallery/none.png";
// import aboutUsArt from "../../asset/image/artist.png";
// import raseImg from "../../asset/gallery/rase.svg";

import aboutUsArtMobile from "../../asset/image/artist_mobile_head.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, EffectCoverflow } from "swiper";
import Flag from "react-world-flags";
import "./Artists.css";
import { Fade } from "react-reveal";

const Artists = () => {
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className="artist-body" id="artist">
      <img
        src={aboutUsArtMobile}
        className="artist_heading d-md-none mx-auto mb-5 w-100 d-block"
        alt=""
      />
      {/* <img src={aboutUsArt} className="artist_heading d-md-block d-none" alt="" /> */}

      <Fade bottom>
        <Swiper
          modules={[Navigation]}
          slidesPerView={4}
          spaceBetween={10}
          // navigation={true}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          loop={true}
          // initialSlide={
          breakpoints={{
            270: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            //   30: {
            //     slidesPerView: 2,
            //     spaceBetween: 14,
            //   },
            // 47: {
            //     slidesPerView: 3,
            //     spaceBetween: 20,
            //   },
            768: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            1024: {
              slidesPerView: 4,
            },
            1524: {
              slidesPerView: 5,
            },
            1924: {
              slidesPerView: 6,
            },
          }}
          onSwiper={setSwiper}
          className="mySwiper"
        >
          {/* 1 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "けだま",
                img: artistJapan,
                country: "Japan",
                code: "JP",
                race: "天使系",
                body: `
                会社勤め、家事育児こなしながらイラスト描いてます。主にファンアートが多いですが、チャンスがあれば色んなものに挑戦したいです。
                今回のプロジェクトも有り難い事にお声がけ頂き、全く新しい挑戦ですが自分のスキルアップ、更には今プロジェクトを成功させるために最大限の力で良いものを作ろうと考えています。
              `,
                pixiv: `https://www.pixiv.net/users/32304575`,
                twit: `https://twitter.com/mqq11`,
              }}
            />
          </SwiperSlide>
          {/* 2 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Eichi",
                img: eichiKP,
                country: "Korea",
                code: "KR",
                race: "人類系",
                body: `
                こんにちは、EICHIです。2019年からPixivに作品をアップロード。
                自分の作品の中には二次的な作品もあります。自分のアイデアを
                実践したいため、主にオリジナル作品に焦点を当てています。
                過去にSFの要素を作品に追加することはあまろなかったので、
                FREEDENプロジェクトは自分にとって新しい試みであり、
                キャラクターに工夫を凝らして多くの時間を費やしました。
                これらの作品やNFTコレクションを気に入っていただければ幸いです。
             
               `,
                pixiv: `https://www.pixiv.net/users/35286171`,
                twit: `https://twitter.com/skskdi12z`,
              }}
            />
          </SwiperSlide>
          {/* 3 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Dema",
                img: demaTaiwan,
                country: "Taiwan",
                code: "TW",
                race: "ねこ娘",
                body: `
                みなさん、こんにちは。私はDemaです。。
                美しいものを描くのが好きなイラストレーターです
                以下は、頻繁に使用されるSNSアカウントです
                私の作品が気に入りましたら、作品の最新の展開をフォローしてください。
               `,
                pixiv: `https://www.pixiv.net/users/7054584`,
                twit: `https://twitter.com/hmw59750476`,
              }}
            />
          </SwiperSlide>
          {/*  4 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Nagi",
                img: nagiImg,
                country: "Japan",
                code: "JP",
                race: "竜族",
                body: `
                皆さん、こんにちは。ナギと申します。
大学卒業後、会社員として生計を立てる傍ら、
隙間時間を見つけて、イラストレター業を行なっております。
よろしくお願いします
             
               `,
                pixiv: `https://www.pixiv.net/users/8095212`,
                twit: `https://twitter.com/shirona_775`,
              }}
            />
          </SwiperSlide>
          {/* 5 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Eva",
                img: evaUkraine,
                country: "Ukraine",
                code: "UA",
                race: "妖精系",
                body: `
                こんにちは、ウクライナのデジタルアーティスト、Evaです。
                私の仕事のほとんどは和風の女性に焦点を当てて描いています。
                平たい絵で感情を表現するのが得意なので、マンガ風を選びました。
                私は特に古いアニメから醸し出される懐かしさが好きです。
                このプロジェクトの作成では、作品「Chobits」からインスパイアーされています。
                この作品のヒロインは、空気のよう透明感があり知的で、洗練されているからです。私の目標は、私の才能を通して、愛らしく、同じ特性を持つキャラクターを作成し、可能な限り現実に近づけることです。
             
               `,
                insta: "https://www.instagram.com/evang3llium/",
                twit: `https://twitter.com/evangellium`,
              }}
            />
          </SwiperSlide>
          {/* 6 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "倉科ゆづき",
                img: 倉科,
                country: "HongKong",
                code: "HKG",
                race: "狐娘",
                body: `
                みなさん、こんにちは。倉科柚月と申します。
                私はゲーム業界のプロイラストレーターです。
                私はかわいいキツネと美しい女の子を描くのが好きです。
                このプロジェクトでは、キツネの女の子の美しさを紹介したいと思います。
                もし私の作品が気に入りましたら、私をフォローして最新の作品を見ることができます。
                `,
                pixiv: `https://www.pixiv.net/users/3398728`,
                twit: `https://twitter.com/KurashinaYuduki`,
              }}
            />
          </SwiperSlide>
          {/* 7 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "zeronext",
                img: zeronextTaiwan,
                country: "Taiwan",
                code: "TW",
                race: "メカ娘",
                body: `
                ゲーム業界出身のイラストレーターで，様々なメカに没頭することが好きです。
                また，神秘系の美少女や機械設備を描くことが好きです。
                もしメカにご興味あれば，私のSNSをフォローしてみてください         
              
                `,
                pixiv: `https://www.pixiv.net/users/3449131`,
                twit: `https://twitter.com/SonZaiKanNai`,
              }}
            />
          </SwiperSlide>
          {/* 8 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "AiRool",
                img: airroolChina,
                country: "China",
                code: "CN",
                race: "悪魔族系",
                body: `
                建築の学士号を取得してゲームアート業界に入りました。
                鮮やかな色の日本のアニメーションアートスタイルが好きな新人アーティストです。
                私はYuanshenプロジェクトチームの先輩をフォローして、日々精進するために勉強をしております。
                `,
                pixiv: `https://www.pixiv.net/users/11173060`,
                twit: `https://twitter.com/C63025522`,
              }}
            />
          </SwiperSlide>
          {/* 9 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Avetetsuya StudiosP",
                country: "Indonesia",
                code: "ID",
                race: "鬼族系",
                img: artistCard2,
                body: `
                Avetetsuya Studiosは、2020年にインドネシアに設立されました。
アジアンスタイルの2Dおよび3Dアート作品の作成に焦点を当てた、
日本の漫画をベースにしたアート受信スタジオです。
私たちは、職人技の精神で高品質のアートデザイン、開発、作成サービスを提供しています。
             
                `,
                twit: `https://twitter.com/TetsunyanS`,
              }}
            />
          </SwiperSlide>
          {/* 9 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "四葉",
                country: "Japan",
                code: "JP",
                race: "人魚系",
                img: 四葉,
                body: `
                ゲーム開発会社にてイラストレーターとして勤務後、
                2014年よりフリーランスのイラストレーターとして活動。
                以降、アプリゲームやPCゲーム、書籍関連の分野にてイラストを制作。
                `,
                twit: ``,
              }}
            />
          </SwiperSlide>
        </Swiper>
      </Fade>
      <div
        className="custom_arrows_artist artist_slider_arrows right_cs_arrow"
        ref={nextRef}
      >
        <img src={arrowRight} alt="" />
      </div>
      <div
        className="custom_arrows_artist artist_slider_arrows left_cs_arrow"
        ref={prevRef}
      >
        <img src={arrowLeft} alt="" />
      </div>
    </div>
  );
};

// SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow]);

export const Gallery = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  let galleryData = [
    { img: eichi_human, race: "人類系" },
    { img: none_img, race: "竜族" },
    { img: none_img, race: "人魚系" },
    { img: none_img, race: "メカ娘" },
    { img: angel_neko, race: "天使系" },
    { img: kitten_dema, race: "ねこ娘 " },
    { img: demon_airool, race: "悪魔族系" },
    { img: fairy_eva, race: "妖精系" },
    { img: none_img, race: "鬼族系" },
    { img: none_img, race: "狐娘" },
  ];

  return (
    <div className="py-5 bg-black">
      <div className="roundSlider  position-relative">
        <div className="roundSlider_container overflow-hidden">
          <div className="raseImg mb-5">
            <span>{galleryData[activeSlide]?.race}</span>
          </div>
          <div className="position-relative">
            <div className="gallery_swiper_main">
              <Swiper
                modules={[Navigation, EffectCoverflow]}
                slidesPerView={3}
                spaceBetween={100}
                effect={"coverflow"}
                centeredSlides
                onSlideChange={(d) => setActiveSlide(d.realIndex)}
                coverflowEffect={{
                  rotate: 0,
                  // stretch: 10,
                  // depth: 55,
                  stretch: 80,
                  depth: 200,
                  modifier: 5,
                  // initialSlide: 3,
                  slideShadows: false,
                }}
                // navigation={true}
                navigation={{
                  prevEl: prevRef?.current,
                  nextEl: nextRef?.current,
                }}
                loop={true}
                // initialSlide={3}
                breakpoints={{
                  // 270: {
                  //   coverflowEffect: false,
                  //   slidesPerView: 2,
                  //   spaceBetween: 15,
                  // },
                  280: {
                    // coverflowEffect: false,
                    slidesPerView: 2,
                    spaceBetween: 50,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 48,
                      depth: 160,
                      modifier: 1,
                      // initialSlide: 3,
                      slideShadows: false,
                    },
                  },
                  780: {
                    coverflowEffect: {
                      rotate: 0,
                      // stretch: 10,
                      // depth: 55,
                      stretch: 44,
                      depth: 145,
                      modifier: 2,
                      // initialSlide: 3,
                      slideShadows: false,
                    },
                  },
                  1700: {
                    coverflowEffect: {
                      rotate: 0,
                      // stretch: 10,
                      // depth: 55,
                      stretch: 44,
                      depth: 175,
                      modifier: 2,
                      // initialSlide: 3,
                      slideShadows: false,
                    },
                  },
                }}
                onSwiper={setSwiper}
                className="mb-5 overflow-visible gallery_coverflow_slider"
              >
                {galleryData?.map((v, i) => (
                  <SwiperSlide key={i}>
                    <div className="galley_slider_img_wrapper">
                      <img className="" src={v.img} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
                {/*               
              <SwiperSlide>
                <img className="galley_slider_img" src={art_2} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_3} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_4} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={angel_neko} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={kitten_dema} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={demon_airool} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={fairy_eva} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_9} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_10} alt="" />
              </SwiperSlide> */}
              </Swiper>
            </div>
            {/*  */}
            <div className="custom_arrows_artist right_cs_arrow" ref={nextRef}>
              <img src={arrowRight} alt="" />
            </div>
            <div className="custom_arrows_artist left_cs_arrow" ref={prevRef}>
              <img src={arrowLeft} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ArtistCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="artist_card_box">
      <img
        className="img-card m-0 p-0 artist_card_img"
        src={data?.img}
        alt=""
      />
      <div className="artist-card-text">
        {/* <p>{data?.name} {" " } {data?.code ? <Flag height="16px" code={ data?.code }  fallback={ data?.country}/> : `(${data?.country})`}</p> */}
        <p>
          {data?.name}{" "}
          {data?.code ? (
            <Flag height="16px" code={data?.code} fallback={data?.country} />
          ) : (
            ""
          )}
        </p>
        {/* <p>{data?.name} {" " } {data?.code ? <Flag height="16px" code={ data?.code }  fallback={""}/> : null}</p> */}
        <p>{`(${data?.race})`}</p>
        <div
          className={
            open
              ? "artist-dropdown-content artist-dropdown-content-show"
              : "artist-dropdown-content"
          }
        >
          <div className="artist-content-img">
            <img src={ArtistContentImgT} alt="" />
          </div>
          <p className="px-2">{data?.body}</p>
          <div className="artist-content-img">
            <img src={ArtistContentImgB} alt="" />
          </div>
          <div className="artist-socials">
            {data?.fb ? (
              <a href={data?.fb} target="blank">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            ) : null}
            {data?.insta ? (
              <a href={data?.insta} target="blank">
                <i className="fa-brands fa-instagram"></i>
              </a>
            ) : null}
            {data?.pixiv ? (
              <a href={data?.pixiv} target="blank">
                {/* <i className="fa-solid fa-p"></i> */}
                <img className="pixivIcon" src={pixivIcon} alt="" />
              </a>
            ) : null}
            {data?.twit ? (
              <a href={data?.twit} target="blank">
                <i className="fa-brands fa-twitter"></i>
              </a>
            ) : null}
          </div>
        </div>
        <div
          className={open ? "img-arr img-arr-active" : "img-arr"}
          onClick={() => setOpen(!open)}
        >
          <img src={ArrowDown} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default Artists;
