import React from "react";
import { Container } from "react-bootstrap";
import metaLogo from "../../asset/image/metaVerse.png";
// import metaVideo from "../../asset/image/loopableversions.mp4";
import "./MetaVerse.css";
import { Fade } from "react-reveal";

const MetaVerse = () => {
  return (
    <div className="metaverse" id="metaverse">
      <Container>
        <Fade bottom>
          <img className="mt-5 pt-5 img-fluid" src={metaLogo} alt="" />
        </Fade>
        <Fade bottom>
          <p className="metaHeading mt-4">
            Stageverse Exclusive Metaverse Club
          </p>
          <p className="ms-md-5 me-md-5 ps-md-5 pe-md-5 meta-description">
          我々は、様々な仮想世界を探索した後、 Stageverseにて我々の最初の
仮想世界を建設することを決めました。
 Stageverseはロサンゼルスのメタバーステクノロジーカンパニーで
開発されたもので、原体験と物理的空間のギャップをできる限り小さく
し、無限のクリエイティブと表現力のポテンシャルを秘めています。
我々が Stageverseを選択した主な理由として、デザインが非常に美
しく、スムーズなユーザーエクスペリエンス、そしてスマートフォン
やVR機械によって体験が可能だからです。我々のNFT保有者は、無料
でクラブに参加することができます。また本物の受付人が接待をし、
毎週イベントを開催します。会員は、クラブ内で自由に交流を楽しむ
ことができます。</p>
        </Fade>
        <Fade bottom>
          <div className="metaVerseVideo">
            {/* <video width="90%" controls>
                    <source src="https://www.youtube.com/embed/7teB25DjciI" type="video/mp4"/>
  
                    Your browser does not support HTML video.
                </video> */}
            <embed
              className="metaVerseVide"
              src="https://www.youtube.com/embed/18XtkJDb3Ig"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay"
              allowfullscreen
            ></embed>
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default MetaVerse;
