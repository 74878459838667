import React from "react";
import { Container } from "react-bootstrap";
import roadmapLogo from "../../asset/roadmap/roadmapLogo.png";
import img1 from "../../asset/roadmap/parse1.png";
import img2 from "../../asset/roadmap/parse2.png";
import img3 from "../../asset/roadmap/parse3.png";
import img4 from "../../asset/roadmap/parse4.png";
import img5 from "../../asset/roadmap/parse5.png";
import img6 from "../../asset/roadmap/parse6.png";
import mob1 from "../../asset/roadmap/mobile1.png";
import mob2 from "../../asset/roadmap/mobile2.png";
import mob3 from "../../asset/roadmap/mobile3.png";
import mob4 from "../../asset/roadmap/mobile4.png";
import mob5 from "../../asset/roadmap/mobile5.png";
import mob6 from "../../asset/roadmap/mobile6.png";
import "./Roadmap.css";
import { Fade } from "react-reveal";

const Roadmap = () => {
  return (
    <div className="roadmap" id="roadmap">
      <Container>
        <div className="roadmap-wraper">
          <Fade bottom>
            <div className="pt-5">
              <img className="img-fluid" src={roadmapLogo} alt="" />
            </div>
          </Fade>

          {/* parse/// */}
          <Fade bottom>
            <div className="parse-step mt-5">
              <img className="img-fluid parse-img" src={img1} alt="" />
              <img className="img-fluid mob-img" src={mob1} alt="" />
              <div className="parse-des">
                <ul>
                  <li>ソーシャルメディアの確立</li>
                  <li>ウェブサイトの立ち上げ</li>
                  <li>モデレーターの採用</li>
                  <li>コミュニティイベントの立ち上げ</li>
                  <li>フリーエデン NFTスマートコントラクトの構築</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="parse-step mt-5">
              <img className="img-fluid parse-img" src={img2} alt="" />
              <img className="img-fluid mob-img" src={mob2} alt="" />
              <div className="parse-des">
                <ul>
                  <li>NFTプレセール</li>
                  <li>NFTの一般販売</li>
                  <li>その他の景品イベント</li>
                  <li>
                    台北国際コミック＆アニメーションフェスティバルにてオフライン
                    <br/>活動
                    7/28~8/1
                  </li>
                  <li>東京コミケでのオフライン活動 8/13~8/14</li>
                  <li>アーティストスカラシッププログラムの開始</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="parse-step mt-5">
              <img className="img-fluid parse-img" src={img3} alt="" />
              <img className="img-fluid mob-img" src={mob3} alt="" />
              <div className="parse-des">
                <ul>
                  <li>メタバーススタッフ研修</li>
                  <li>メタバースクラブ開設</li>
                  <li>第1回Vtuberミートアップイベント</li>
                  <li>オンラインオークションの開催</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="parse-step mt-5">
              <img className="img-fluid parse-img" src={img4} alt="" />
              <img className="img-fluid mob-img" src={mob4} alt="" />
              <div className="parse-des mt-4">
                <ul>
                  <li>初のオンラインアーティスト展を開催</li>
                  <li>初のアニメコンサートを開催</li>
                  <li>オフライン活動 アニメNYC 11/18-11/20</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="parse-step mt-5">
              <img className="img-fluid parse-img" src={img5} alt="" />
              <img className="img-fluid mob-img" src={mob5} alt="" />
              <div className="parse-des">
                <ul>
                  <li> 3D NFTの無料配布</li>
                  <li>3D NFTの先行販売</li>
                  <li>3D NFTの一般販売</li>
                  <li>3D NFT保有者向けの新機能を発表</li>
                  <li>3D NFTの景品プールを開始</li>
                </ul>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="parse-step mt-md-5 last_rd_step">
              <img className="img-fluid parse-img" src={img6} alt="" />
              <img className="img-fluid mob-img w-100" src={mob6} alt="" />
              <div className="parse-des">
                <ul>
                  <li className="pe-3">オンチェーン投票プラットフォームの立ち上げ</li>
                  <li>
                    ュニティーの意見に基づき、ファンやアーティスト向けの <br/>機能をさらに追加
                  </li>
                </ul>
              </div>
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  );
};

export default Roadmap;
