import { useState } from "react";
import { Link } from "react-router-dom";

const Navigation2 = ({ mintPage }) => {
  const [menu, setMenu] = useState(false);

  const closeMenu = () => {
    setMenu(false);
  };
  return (
    <>
    <button onClick={()=> setMenu(!menu)} id="cs_menuToggle" className={menu ? "opened_nav" : ""}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    <nav id="cs_navigation" className={menu ? "opened_nav" : ""}>
      <ul id="cs_menu">
        <Link onClick={closeMenu} to="/">
          <li>トップページ</li>
        </Link>
        {mintPage ? (
          <Link onClick={closeMenu} to="/mint">
            <li>ミント領域</li>
          </Link>
        ) : (
          <Link onClick={closeMenu} to="/artistRecruit">
            <li>芸術家募集</li>
          </Link>
        )}
        {/* <a href="https://mint.freeden.io" target='_blank' rel="noreferrer"><li>Mint Page</li></a> */}
      
        <hr />
        <p className="text_pr mb-3">
        言語オプション
        </p>
        <a href="https://freeden.io/" rel="noreferrer"><li>{">"} English</li></a>
        <a href="https://zh.freeden.io/" rel="noreferrer"><li>{"> "} 中文 </li></a>
      </ul>
    </nav>
    
   {menu && <div className="nav_backdrop" onClick={closeMenu}></div>}
   </>
  );
};

export default Navigation2;
