import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import giveWayLogo from "../../asset/giveway/giveWayLogo.png";
import img1 from "../../asset/giveway/img1.png";
import img2 from "../../asset/giveway/img2.png";
import img3 from "../../asset/giveway/img3.png";
import img4 from "../../asset/giveway/img4.png";
import img5 from "../../asset/giveway/img5.png";
import img6 from "../../asset/giveway/img6.png";

import "./GiveWay.css";
import { Fade } from "react-reveal";

const GiveWay = () => {
  return (
    <div className="giveWay" id="giveaway">
      <div className="wrapper">
        <Container>
          <Fade bottom>
            <div>
              <img className="mt-5 img-fluid pb-4" src={giveWayLogo} alt="" />
              <p className="giveWayHeading">毎週の抽選会とプレゼントの配布</p>
              <p className="metaHeading fs-5">
                様々なアーティストの作品を無料でエアドロップ
              </p>
              <p className="meta-description mb-5">
                あなたが最も好きなデジタル作品コレクションを広げたいですか？
                我々は、世界中の才能あるACGアーティストとコラボして、我々の会員様
                を紹介します。アーティストの作品は、抽選とエアドロップ方式で会員様
                にプレゼントをします
              </p>
            </div>
          </Fade>
          <div className="mt-5 pb-5">
            <Fade bottom>
              <div className="giveaway-cards-gb">
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                  <Col>
                    <Card className="eventCard">
                      <Card.Img variant="top" src={img1} />
                    </Card>
                  </Col>
                  <Col>
                    <Card className="eventCard">
                      <Card.Img variant="top" src={img2} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <div>
              <img className="mt-5" src={eventLogo} alt="" />
              <p className="lotery-text">ウィクリー抽選会</p>
              <p className="lotery-text fs-6">
                毎週、さまざまな賞品の抽選を開催しています:
              </p>
              <div className="coins">
                <img className="img-fluid-coins" src={img3} alt="" />
                <img className="img-fluid-coins" src={img4} alt="" />
                {/* <img className="img-fluid" src={img3} alt="" />
                <img className="img-fluid" src={img4} alt="" /> */}
              </div>
              <div className="coin-des">
                <p>
                  <span className="under-coin">暗号通貨： </span>
                  Stablecoins / ETH /BNB/その他の暗号通貨
                  <br />
                  <span className="under-coin">
                    デジタルクレジットカード：
                  </span>{" "}
                  Amazon / Netflix/Steamギフトカードなど
                  <br />
                  <span className="under-coin">物理的な賞品：</span>
                  マウスパッド/パワーバンク/パーカー/Tシャツ/帽子
                </p>
              </div>
              <div>
                <p className="meta-description fs-6">
                  {/* FREEDEN 2D NFT holders can mint our 3D NFT for free. FREEDEN
                  3D NFTs not only have a unique appearance but also have
                  exclusive utilities and rewards. <br /> */}
                  <br />
                  FREEDEN 2D NFTホルダーは、3DNFTを無料で作成できます。 FREEDEN
                  3D
                  NFTは、独特の外観を備えているだけでなく、独自のエンパワーメントと報酬も備えています。
                  私たちは、暗号通貨を含むさまざまなギフトで毎週ラッフルを開催しています、
                  <br /> <br />
                  もっとユニークなACGアートコレクションが欲しいですか？私たちは世界中の才能あるアーティストと提携し、彼らをメンバーに紹介し、彼らの作品を抽選して空中投下します"
                </p>
              </div>
            </div>
          </Fade>

          <Fade bottom>
            <div className="mx-md-5">
              <img className="mt-5" src={eventLogo} alt="" />
              <p className="lotery-text">無料のFREEDEN 3DNFT</p>
              <p className="give-ways-des coin-des px-2">
                初期の2DNFTの保有者は、3DNFTを無料で作成できます。 FREEDEN NFT
                3Dバージョンは、ACG業界に対する私たちの一貫した信念を表現し
                、私たちのサポーターとACGコミュニティへのさらなるコミットメント
                と感謝の気持ちを示しています。これらの3DNFTはより価値があるだけ
                でなく、保有者は追加のメンバーシップ特典も受け取ることができます！
                さらに良いことに、保持するFREEDEN NFT（2Dおよび3D）が多いほど、
                より多くの収益が得られ、将来の抽選会で勝つ可能性が高くなります。
              </p>
            </div>
          </Fade>
          <div className="mt-5 pb-5 mx-md-5 px-md-4">
            <Fade bottom>
              <div className="giveaway-cards-2-bg">
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                  <Col>
                    <Card className="eventCard mx-2">
                      <Card.Img variant="top" src={img5} />
                    </Card>
                  </Col>
                  <Col>
                    <Card className="eventCard mx-2">
                      <Card.Img variant="top" src={img6} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            {/* <div className='mx-md-5 pb-5'>
            <img className='mt-5' src={eventLogo} alt="" />
                <p className='lotery-text'>
                       3D Freden NFT giveaways
                </p>
                <p className='give-ways-des coin-des pb-5'>
                    All FreEden holders will get a free Freeden 3D for free. The 3D version of FreEden NFT is a
                    further promise and appreciation to our supporters and ACG communities, which expresses
                    our consistent faith in the ACG industry. These 3D NFTs not only have higher value, but the
                    holders will have additional member benefits! Again for our appreciation to all initial
                    supporters, these NFTs will be distributed to them; they only need to cover the gas fee. Even
                    better is that the more FreDen NFTs (both 2D & 3D) you hold, the more benefits you get, and
                    the higher winning chance you get in future raffle events!
                </p>
            </div> */}
          </Fade>
        </Container>
      </div>
      {/* <a href="#offline"><button className='scrollTop mb-5' style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default GiveWay;
