import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import corner1 from "../../asset/roadmap/leftCorner.png";
// import corner2 from "../../asset/roadmap/rightCorner.png";
import desktop2 from "../../asset/roadmap/desktop2.png";
import "./Card.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

const Card = () => {
  let cardsData = [
    {
      body: `
      1. ACGアーティストにマンツーマンでコーチングセッションを行い、NFTの制作方法を指導します。具体的に説明し、市場とのギャップを明らかにします。   `,
    },
    {
      body: `
       アーティストがファンを増やすことができるように、私たちの熱心なフォローを利用します。バーチャルやリアルなミートアップを開催し、ユニークで貴重なネットワーキングの機会を提供します。
    `,
    },
    {
      body: `
      一部のアマチュアプロジェクトの制作資金として、奨学金制度を立ち上げます。私たちが保有するジェネシスミントの資金を使い、有望な才能が正当な評価を受ける支援を行います。これを利用して、より多くのACGアーティストがこのスペースに参加するよう働きかけていきます。
    `,
    },
    {
      body: `
     ACGで成功したアーティストを雇い、ワークショップを開催し、経験の浅いアーティストに、自分自身の成功を見出す貴重なレッスンをしてもらいます。
    `,
    },
    {
      body: `
      売上金の一部でメタバースの土地を購入し、美術展をオンライン化します。ACGの作品発表の文化を変え、アーティストが多くの売上を上げれるようにしたいと考えています。    `,
    },
  ];
  return (
    <div>
      <Container>
        <div className="card-background">
          <p className="card-heading fw-bold">その方法は以下の通りです:</p>
          <div className="d-md-block d-none">
            <Row className="card-first-row">
              {cardsData?.map((v, i) => (
                <Col key={i} className="col-12 mb-3 col-md-4">
                  <div className="card-box p-3 ">
                    <img className="corner-left" src={corner1} alt="" />
                    <h1 className="heading-num">{i + 1}.</h1>
                    <p className="guide-description">{v?.body}</p>
                  </div>
                </Col>
              ))}
              <Col className="col-md-2"></Col>
            </Row>
            <div>
              <img className="mt-5 img-fluid pb-5" src={desktop2} alt="" />
            </div>
          </div>
          <div className="d-md-none d-block">
            <Swiper
              slidesPerView={1}
              spaceBetween={24}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper pb-3 text_card_slider"
            >
              {cardsData?.map((v, i) => (
                <SwiperSlide key={i}>
                  <div className="card-box p-3 m-4">
                    <img className="corner-left" src={corner1} alt="" />
                    <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">
                      {i + 1}.
                    </h1>
                    <p className="guide-description">{v?.body}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Card;
