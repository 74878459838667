import { useState } from "react";
import { Container } from "react-bootstrap";
// import banner from "../../Component/asset/image/metaverseBeg.jpg";
import Navigation2 from "../Shared/Navigation2/Navigation2";
import img1 from "../asset/mint/begLt.png";
import img2 from "../asset/mint/begRt.png";
import img3 from "../asset/mint/begLb.png";
import img4 from "../asset/mint/begRb.png";
import circle from "../asset/mint/mint.gif";
import endImg from "../asset/mint/desEnd.png";
import banner1 from "../asset/mint/story1.jpg";
import banner2 from "../asset/mint/fighting-angels-w.jpg";
import banner3 from "../asset/mint/freeden-new-world-w.jpg";
import "./Mint.css";
import Footer from "../Shared/Footer/Footer";

const MintNft = () => {
  const [showButton, SetshowButton] = useState(false);
  const [mintCount, setMintCount] = useState(1);

  const handleMintCount = (type) => {
    switch (type) {
      case "+":
        setMintCount(mintCount + 1);
        break;
      case "-":
        if (mintCount - 1 > 1) {
          setMintCount(mintCount - 1);
        } else {
          setMintCount(1);
        }
        break;
      default:
        return mintCount;
    }
  };
  return (
    <div>
      {/* <div className='banner' id='home'> */}
      <div className="navigation-position">
        <Navigation2 />
      </div>
      <div className="mint-banner-background-img"></div>
      {/* <img className='deskTwo-banner' src={banner} alt=""/> */}
      {/* </div> */}
      <div className="desktop-three-back">
        <Container>
          <div className="desk-3">
            <div className="minit-res">
              {/* <p>
                    Thousands of years ago, before the peace that we know today, the world knew of chaos. It
                    ripped through society, crumbling structures and decaying peace with little resistance. The
                    world’s only hope was a group of wise and powerful Sages. Blessed with divine power, the sages came together to cast out the chaos, banishing it from
                    our mortal plane. In order to prevent the resurrection of this evil, they created a powerful
                    rune that would be called upon if evil ever showed its face again.
                    Now it is happening again… Chaotic energy has seeped its way into the world once more,
                    and the holy rune has been deployed to save humanity. Split into five thousand sealed
                    genesis bondings, the rune’s mission is to find those not yet tainted by chaos and save
                    them. Once a bonding has been successfully initiated, a guardian angel is deployed to watch over
                    the individual that has been blessed with it. They must be guided to a new world, one where
                    they can be safe forever. They must find FreEden FREEDEN is a holy realm whose strength and protection from chaos come with each
                    successful bond finding its way there. Therefore, each of the five thousand bonds must find
                    their way to this holy realm, to restore the strength of the people, and invoke the true power
                    of the guardian angels.
                    </p> */}

              {/* border images */}
              <img className="corner1" src={img1} alt="" />
              <img className="corner2" src={img2} alt="" />
              <img className="corner3" src={img3} alt="" />
              <img className="corner4" src={img4} alt="" />

              {/*  */}
              <div className="new-story-section">
                <div className="mb-md-5 mb-3">
                  <img className="img-fluid" src={banner1} alt="story one" />
                </div>
                <p>
                  その昔、誰も覚えていないほど昔、私たちの知る世界を闇が飲み込もうとしていた。闇は大地を切り裂き、山を切り崩し、立ち向かおうとする者を滅ぼした。海や川が干上がり、人類の未来はゆっくりと、しかし屈辱的な敗北に終わると思われた。
                  しかし、「賢者」が現れる。宇宙を救うために生まれてきた10人の聖者たち。
                  賢者に授けられた聖なる力は、かつて闇がもたらした災厄を封じ込めるのに十分であったが、彼らは闇の再来を恐れていた。そこで彼らは、この星から闇を追い出すために、自分たちの魂の一部を融合させて強力な破滅を作り出した。それは、大切に思っている世界から、混沌を取り除くのに十分なものだった...
                </p>

                <div className="my-4 text-center">
                  {showButton ? null : (
                    <button
                      type="button"
                      className="learn-more mx-auto"
                      onClick={() => SetshowButton(true)}
                    >
                      Learn More{" "}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {showButton && (
                  <>
                    <div className="my-md-5 my-3">
                      <img className="img-fluid" src={banner2} alt="" />
                    </div>
                    <p>
                      しかし、その努力も束の間だった。 世界征服を阻まれた ""闇
                      ""は、再びその姿を現した。雲行きが怪しくなり、空は真っ暗になり、春の花々は凍てつくような寒風にさらされ、混沌とした雰囲気が漂う。
                      ルーンは今回、宇宙を破壊しに来た邪悪な力を捕らえるには十分ではなかった。賢者の無限の魔力をもってしても、シールドを作るどころか、人類の最後の希望となる6000 の「創世の絆」に砕け散った。
                      崩壊した世界を守ることはもはや無意味であり、絆たちの任務は、まだ闇に飲み込まれていない少数の人々を救出し、救うというシンプルなものである。
                      世界の他の地域と同様に滅亡を恐れる空は、有能な天使を送り、6000人の純粋な魂を保護し、最後に残った安全なヘイブン、フリーエデンに導く準備ができているのである。他の魂は枯れ果てているため、天使を見れるのは、ボンディング契約を成功させた者のみである。
                      天使たちの導きのもと、新しい世界が待っているのです。辛い過去は忘れて、あなたの天使と永遠に一緒にいられる場所、""フリーエデン
                      ""を発見してください。
                    </p>
                    <div className="my-md-5 my-3">
                      <img className="img-fluid" src={banner3} alt="" />
                    </div>
                    <p>
                      神の力に恵まれた賢者たちは、混沌を追い出し、人間界から追放した。この悪の復活を防ぐために、彼らは強力なルーンを作り、悪が再び顔を出したときに呼び出されるようにした。
                      そして今、再び悪夢が...混沌のエネルギーは再び世界に浸透し、聖なるルーンは人類を救うために動き出した。6000の封印された「創世の絆」に分割されたルーンの使命は、まだ混沌に汚染されていない人々を見つけ出し、救うことである。
                      絆が成功すると守護天使が現れ、絆を受けた者を見守る。彼らは永遠に安全な新しい世界へ導きかれ、フリーエデンを目にすることになる。
                      フリーエデンは聖なる領域であり、絆が結ばれることで混沌から身を守れる。そのため、6000の絆のそれぞれが、人々の力を回復し、天使の真の力を呼び起こすために、この聖なる領域への道を見つけなければならないのです。
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
            <div className="pt-5 mt-2 img-container">
            <h2 className="text_pr">ミント領域</h2>
            <img className="img-fluid circle-img" src={circle} alt="" />
            <div className="row my-md-4 my-1">
              <button
                onClick={() => handleMintCount("-")}
                className="trianglebtnLeft"
              ></button>
              <div className="mint_count_box d-flex justify-content-center align-items-center">
                {mintCount}
              </div>
              <button
                onClick={() => handleMintCount("+")}
                className="trianglebtnRight"
              ></button>
            </div>
            <img className="img-fluid endImg" src={endImg} alt="" />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default MintNft;
