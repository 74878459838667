import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import giveWayLogo from "../../asset/giveway/giveWayLogo.png";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import event1 from "../../asset/offlineEvents/img1.png";
import event2 from "../../asset/offlineEvents/img2.png";
import event3 from "../../asset/offlineEvents/img3.png";
import event4 from "../../asset/offlineEvents/img4.png";
import event5 from "../../asset/offlineEvents/img5.png";
import event6 from "../../asset/offlineEvents/img6.png";
import event7 from "../../asset/offlineEvents/img7.png";
import "./OfflineEvents.css";

//
const OfflineEvents = () => {
  const [showButton, SetshowButton] = useState(false);

  //
  const ToggleText = () => {
    SetshowButton(!showButton);
  };

  // const [windowSize, setWindowSize] = useState(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     let size =window.innerWidth;

  //    if(size > 768){
  //     SetshowButton(true);
  //   }else{
  //     SetshowButton(false);
  //    }
  //     // setWindowSize(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("load", function () {
  //     handleResize()
  //   });

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  return (
    <div className="offlineEvents" id="offline">
      <Container>
        <Fade bottom>
          <div>
            <img
              className="mt-5 img-fluid pb-4 pt-5"
              src={giveWayLogo}
              alt=""
            />
            <p className="giveWayHeading text-uppercase">オフライン特典</p>
          </div>
        </Fade>
        <div>
          <Fade bottom>
            <img className="mt-5" src={eventLogo} alt="" />
            <p className="lotery-text ">グローバルコミックコンサポート</p>
          </Fade>
        </div>
        {/* events card div/// */}
        <section className="offline-event-wraper">
          <Fade bottom>
            <div>
              <p className="support-text" style={{ opacity: ".8" }}>
                世界では、数多くのアニメやゲームのコンベンションが開催されています。しかし、
                残念なことに、ファンやアーティストが不便なため、飲食をしないことが頻繁にあ
                ります。世界中の主要なコンベンションで、私たちは無料で物資の提供や販売を行
                います。屋台や展示ブースの貸し出しなど、簡単なもので結構です。
              </p>
              <div className="off-event-img">
                <img src={event1} alt="" />
                {showButton && (
                  <img className="d-block d-md-none" src={event2} alt="" />
                )}
                <img className="d-md-block d-none" src={event2} alt="" />
              </div>
              {/* mobile content */}
              {showButton && (
                <div className="d-md-none d-block">
                  <div>
                    <p
                      className={
                        showButton
                          ? "support-text show-text-active"
                          : "support-text show-text"
                      }
                      style={{ opacity: ".8" }}
                    >
                      トル入りの水を全員に無料で提供しますし、希望すればお弁当を購入することも
                      できます。 ただし、フリーエデン NFT
                      をお持ちの方は全食事無料、お弁当の優先販売権もあり
                      ます。また、コーヒー、紅茶、スナック菓子など、飲食物の選択肢を増やす予定で
                      す。 またフリーエデン NFT ホルダー専用の休憩所や、VIP
                      受付によるアーティストコミ
                      ュニケーション休憩所も設置します。 ACG
                      の専用イベントに参加しながら、アニメファンに応援されていることを実感し
                      ていただきます。フリーエデンは、世界各地で開催される ACG
                      の主要コンベンショ
                      ンに参加します。コンベンションでの商品販売による利益は、NFT
                      ホルダーにも還 元されます。
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event3} alt="" />
                    <img src={event4} alt="" />
                  </div>
                  <div>
                    <p className="support-title">代行列並びサービス：</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      あなたはこれらの人々の疲れた目を見たことはありますでしょうか？
                      限定版のACG商品を待ち行列に並ぶ皆さんに朗報です。お腹が空き、
                      睡眠不足のときに大きなバッグを待つのは本当に苦痛かですよね。
                      特定の展示会で限られた数の商品を購入するために並ぶ必要がある場合、
                      弊社は5人の幸運な当選者を集めて無料の代行列並びサービスを提供します。
                      勝者は、このサービスを他の人に転売または贈与することもできます。
                    </p>

                    <p className="support-title">オフライン展示会:</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      ファンの皆様にお気に入りのアーティストと交流する機会を提供するため、
                      展覧会終了後にパーティーを開催します。
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event5} alt="" />
                    <img src={event6} alt="" />
                  </div>
                  <div>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      アーティストを招待して、一緒に祝い、アーティストとファンの絆を強め、
                      パーティーで特別な商品を提供するイベントも開催します。
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img className="w-100" src={event7} alt="" />
                  </div>
                  <div>
                    <p className="support-title">
                      すべての料金はFREEDENが負担します。
                    </p>
                  </div>
                </div>
              )}
              {/* desktop content */}
              <div className="d-md-block d-none">
                <div>
                  <p
                    className={
                      showButton
                        ? "support-text show-text-active"
                        : "support-text show-text"
                    }
                    style={{ opacity: ".8" }}
                  >
                    トル入りの水を全員に無料で提供しますし、希望すればお弁当を購入することも
                    できます。 ただし、フリーエデン NFT
                    をお持ちの方は全食事無料、お弁当の優先販売権もあり
                    ます。また、コーヒー、紅茶、スナック菓子など、飲食物の選択肢を増やす予定で
                    す。 またフリーエデン NFT ホルダー専用の休憩所や、VIP
                    受付によるアーティストコミ
                    ュニケーション休憩所も設置します。 ACG
                    の専用イベントに参加しながら、アニメファンに応援されていることを実感し
                    ていただきます。フリーエデンは、世界各地で開催される ACG
                    の主要コンベンショ
                    ンに参加します。コンベンションでの商品販売による利益は、NFT
                    ホルダーにも還 元されます。
                  </p>
                </div>
                <div className="off-event-img">
                  <img src={event3} alt="" />
                  <img src={event4} alt="" />
                </div>
                <div>
                  <p className="support-title">代行列並びサービス：</p>
                  <p className="support-text" style={{ opacity: ".8" }}>
                    あなたはこれらの人々の疲れた目を見たことはありますでしょうか？
                    限定版のACG商品を待ち行列に並ぶ皆さんに朗報です。お腹が空き、
                    睡眠不足のときに大きなバッグを待つのは本当に苦痛かですよね。
                    特定の展示会で限られた数の商品を購入するために並ぶ必要がある場合、
                    弊社は5人の幸運な当選者を集めて無料の代行列並びサービスを提供します。
                    勝者は、このサービスを他の人に転売または贈与することもできます。
                  </p>
                  <p className="support-title">オフライン展示会:</p>
                  <p className="support-text" style={{ opacity: ".8" }}>
                    ファンの皆様にお気に入りのアーティストと交流する機会を提供するため、
                    展覧会終了後にパーティーを開催します。
                  </p>
                </div>
                <div className="off-event-img">
                  <img src={event5} alt="" />
                  <img src={event6} alt="" />
                </div>
                <div>
                  <p className="support-text" style={{ opacity: ".8" }}>
                    アーティストを招待して、一緒に祝い、アーティストとファンの絆を強め、
                    パーティーで特別な商品を提供するイベントも開催します。
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <img className="w-100" src={event7} alt="" />
                </div>
                <div>
                  <p className="support-title">
                    すべての料金はFREEDENが負担します。
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="mt-2 d-md-none d-block text-center">
                {showButton ? null : (
                  <button
                    type="button"
                    className="learn-more mx-auto"
                    onClick={ToggleText}
                  >
                    Learn More{" "}
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    {/* {!showButton ? "Learn More " : "Minimize "} {" "} */}
                    {/* {!showButton ?
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    :
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>} */}
                  </button>
                )}
              </div>
            </div>
          </Fade>
        </section>
        {/* <a href="#voting"><button className='scrollTop mt-3' >↡</button></a> */}
      </Container>
    </div>
  );
};

export default OfflineEvents;
