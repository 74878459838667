import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../asset/ourTeam/teamLogo.png";
import topLine from "../../asset/metaEvents/topLine.png";
import bottomLine from "../../asset/metaEvents/bottomLine.png";
import img1 from "../../asset/ourTeam/Rayj.png";
import img2 from "../../asset/ourTeam/Taichi.png";
import img3 from "../../asset/ourTeam/TojunnS.png";
import img4 from "../../asset/ourTeam/Kenji.png";
import img5 from "../../asset/ourTeam/ShiShi.png";
import img6 from "../../asset/ourTeam/Shaka.png";
import "./OurTeam.css";
import { Fade } from "react-reveal";

const OurTeam = () => {
  let teamData = [
    {
      name: `レイ J`,
      img: img1,
      role: `創業者`,
      body: `
レイは、大手テクノロジー企業で部門リーダーやディレクターとして長年勤務した経験があり、クラウドやインターネット技術、戦略的思考に精通しています。またブロックチェーン愛好家であり、NFT投資家でもあります。最近の好きなアニメは「呪術廻戦」と「僕のヒーローアカデミア」です。

      `,
    },
    {
      name: `TaiChi`,
      img: img2,
      role: `ブランド戦略マネージャー`,
      body: `
太一は、日本語、英語、中国語を話す日本のアニメブランドアンバサダー
です。さまざまな文化の利点を統合する戦略を深く理解しており、
リソースが豊富な多国籍KOLネットワークを構築することを強みとしています。
彼は、FREEDENチームを率いて、アニメーションアーティストとファンを結び付け
ることができるプラットフォームを構築することに取り組んでいます。
      `,
    },
    {
      name: `Tojunn S`,
      img: img3,
      role: `マーケティングマネージャー`,
      body: `
      ソーシャルリスニング分析＆WOMマーケティング戦略スペシャリスト、Gamefi＆NFTの長期投資家、オブザーバー ARPGとオープンワールドゲームが大好きで、アニメファンで血が騒ぐ。現在、NFTとメタバース普及のため専門知識を提供中。
      `,
    },
    {
      name: `Kenji W`,
      img: img4,
      role: `プロジェクトアドバイザー`,
      body: `
      ケンジは経験豊富な暗号トレーダーであり、何年にもわたってプロの暗号リサーチチームを率いてきました。
またオープンワールドのサバイバルゲームやMMORPGに夢中で、進撃の巨人、エヴァンゲリン、ガンダムW、るろうに剣心の大ファンでもあります。
`,
    },
    {
      name: `ShiShi`,
      img: img5,
      role: `コミュニティーマネージャー`,
      body: `
      ACGの文化研究に長年の経験を持っています。また世界中のアーティストとのコネクションも豊富。ACGのアートワークに対して独自の見解を持っており、コミュニティを構築することにも長けています。彼のマスタープランは、異なる文化や国のACG愛好家が集まるボーダレスコミュニティを作ることです。
      `,
    },
    {
      name: `Shaka O`,
      img: img6,
      role: `技術スペシャリスト`,
      body: `
      シャカはアニメ文化に情熱を注ぐアニメ愛好家であり、背景を勉強しています。彼は長年の暗号データと技術研究に基づく、経験豊富な暗号取引の専門家です。

またハードコアゲーマーでもあり、チームワークで課題を克服することが好きです。
      `,
    },
  ];
  return (
    <div className="ourteam-section" id="ourTeam">
      <Container>
        <Fade bottom>
          <div>
            <img className="img-fluid mt-5 pt-3" src={logo} alt="" />
          </div>
        </Fade>
        <div className="ourteam mt-4 pb-5">
          <img src={topLine} alt="" />
          {/*  desktop card style for team*/}
          <div className="desktop-team-members">
            <Row className="card-wraper g-4">
              {teamData?.map((v, i) => (
                <Col key={i} className="col-md-6">
                  <Fade bottom>
                    <div className="team-card mx-5 my-3">
                      <img
                        className="img-fluid card-img"
                        src={v?.img}
                        alt={v?.name}
                      />
                      <p className="team-title">{v?.name} </p>
                      <p>({v?.role})</p>
                      <p className="team-des">{v?.body}</p>
                    </div>
                  </Fade>
                </Col>
              ))}
            </Row>
          </div>
          {/*  mobile slider for team */}
          <div className="mobile-team-section-swiper">
            <Swiper
              slidesPerView={1}
              spaceBetween={24}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper pb-3"
            >
              {teamData?.map((v, i) => (
                <SwiperSlide key={i}>
                  <div className="team-card mx-auto">
                    <div className="team-member-img">
                      <img
                        className="img-fluid card-img"
                        src={v?.img}
                        alt={v?.name}
                      />
                    </div>
                    <p className="team-title">{v?.name} </p>
                    <p>({v?.role})</p>
                    <p className="team-des">{v?.body}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <img src={bottomLine} alt="" />
        </div>
      </Container>
      {/* <a href="#roadmap"><button className='scrollTop mt-3' >↡</button></a> */}
    </div>
  );
};

export default OurTeam;
