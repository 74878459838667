import React from "react";
import deskTwoBanner from "../../../imagee/secondBanner.jpg";
import ourMissionLogo from "../../asset/metaEvents/eventsLogo.png";
import TopBannerLogo from "../../../imagee/deskTwoLogo.jpg";
import MarginOne from "../../../imagee/missionMarginOne.jpg";
import MarginTwo from "../../../imagee/missionMargintwo.jpg";
import sketchNew from "../../../imagee/artist-drawing-anime-w.jpg";
import skatch from "../../../imagee/skatch.jpg";
import "./Banner.css";
import Card from "../Card/Card";
import { Container } from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../../Shared/Footer/Footer";
import Navigation2 from "../../Shared/Navigation2/Navigation2";

const Banner = () => {
  const [showButton, SetshowButton] = React.useState(false);
  return (
    <div className="desktop2-beg" id="home">
      <div className="banner recuiter_bg">
      <Navigation2 mintPage/>
        <img className="artistRecruit-banner d-md-block d-none" src={deskTwoBanner} alt="" />
        <img
          className="artistRecruite-banner-logo img-fluid"
          src={TopBannerLogo}
          alt=""
        />
      </div>

      <div className="ourmission-style bg-white">
        <div className="our-mission-logo text-center pt-5">
          <img src={ourMissionLogo} alt="" />
        </div>
        <div>
          <p className="mission-text pb-2">OUR MISSION</p>
          <img className="img-fluid" src={MarginOne} alt="" />
          <p className="mission-description px-4">
          我々はアニメやゲームの長年のファンとして、ACG業界の多くの
アーティストと知り合いました。彼らとの会話で最もよく耳にするのは、
どのようにパートタイムからフルタイムへ仕事を移行させるかです。
多くのアーティストは当初、熱心で野心家でした。しかし、大部分の
アーティストは、砂漠の蜃気楼でオアシスを求める孤独な旅人のように
絶え間ない障害と終わりのない孤独に襲われるとは思ってもいませんでした。
「この道は果たして有望なのだろうか」{showButton && `「これはなんだ？自分は本物のアートを描いているのか？」「毎日描き続けて、生活を保つことはできるのか？」...これらはアーティストなら誰しもが一度は抱えた悲痛な状況です。「毎日アーティストという仕事を続けようか考えいている」とアーティストの友人が笑顔で言った時に、我々はその背後にある悲しみを深く感じました。
少数派の芸術家の何人かは、より多くの芸術作品を蓄積し、有名になるのは十分な運が必要と回答しまいた。ゆっくりと、彼らは自分の道を見つけ、毎日夢を実現し続けることができました。
しかしながら、チャンスに恵まれず多くの才能のある芸術家は、親戚や友人の圧力と人生の残酷さに抵抗することができず、ゆっくりと彼らの夢をあきらめなければない状況にあります。`}
         </p>
          <div className="my-4 text-center">
            {showButton ? null : (
              <button
                type="button"
                className="learn-more mx-auto bg-pr text-dark"
                onClick={() => SetshowButton(true)}
              >
                Learn More <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </button>
            )}
          </div>
          {showButton ? (
            <p className="mission-description px-4">
            現在、アートの世界ではNFTとメタバースについてはほとんど知られていません。NFTの分散型テクノロジーによって、仮想世界ではデジタル資産の「真の所有権」を得ることができると考えています。 2021年の前半期、多くの有名なブランドやアーティストが独自のNFTを発行し、ますます多くの人々がこの斬新なコンセプトを徐々に受け入れるようなってきました。 NFTは単なる絵ではなく、独特な特徴と永続的な保存の機能があります。そのためNFTはACGアーティストにとって素晴らしい機会であると考えています。多くのACGアーティストを支援することで、NFTを通じて認知度を高めたいと考えています。彼らのデジタルアートを売って生計を立てるだけでなく、我々はさらに多くの支援をアーティストに提供したいと考えています。

            </p>
          ) : null}
          <img className="img-fluid pb-5" src={MarginTwo} alt="" />
          <Container>
            <img className="skatch" src={sketchNew} alt="" />
          </Container>
        </div>
      </div>
      <div className="back-style">
        <Container className=" pb-5">
          <div className="container-color text-center">
            <div className="our-mission-logo pt-5">
              <img src={ourMissionLogo} alt="" />
            </div>
            <div>
              <span className="acg-artist">ACG Artist Support Community</span>{" "}
              <br />
              <br />
              <p className="acg-artist-description px-4">
              私たちのミッションと価値の核心は、ACGアーティストへの支援であり、実現したい理由はいくつかあります。世界的なパンデミックにより、クリエーターは重い制約を受け、現実世界では作品を売ることが難しくなりました。だからこそ、私たちは番組をWeb3.0に対応させ、しばしば過小評価されがちなグループ、ACGアーティストに実用性を提供したいと考えたのです。彼らにNFTアートワークを作成するツールを提供し、各分野で進歩する手助けをしたいと思います。
              </p>
            </div>
          </div>
        </Container>
        <div className="card-area-style">
          <Card></Card>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Banner;
